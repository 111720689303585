import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Todo from './Todo.vue';
var STORAGE_KEY = 'todos';
var filters = {
  all: function all(todos) {
    return todos;
  },
  active: function active(todos) {
    return todos.filter(function (todo) {
      return !todo.done;
    });
  },
  completed: function completed(todos) {
    return todos.filter(function (todo) {
      return todo.done;
    });
  }
};
var defalutList = [{
  text: '工作流功能绘制工具',
  done: false
}, {
  text: '工作流流转方法',
  done: false
}, {
  text: '自动化代码优化',
  done: false
}];
export default {
  components: {
    Todo: Todo
  },
  filters: {
    pluralize: function pluralize(n, w) {
      return n === 1 ? w : w + 's';
    },
    capitalize: function capitalize(s) {
      return s.charAt(0).toUpperCase() + s.slice(1);
    }
  },
  data: function data() {
    return {
      visibility: 'all',
      filters: filters,
      // todos: JSON.parse(window.localStorage.getItem(STORAGE_KEY)) || defalutList
      todos: defalutList
    };
  },
  computed: {
    allChecked: function allChecked() {
      return this.todos.every(function (todo) {
        return todo.done;
      });
    },
    filteredTodos: function filteredTodos() {
      return filters[this.visibility](this.todos);
    },
    remaining: function remaining() {
      return this.todos.filter(function (todo) {
        return !todo.done;
      }).length;
    }
  },
  methods: {
    setLocalStorage: function setLocalStorage() {
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(this.todos));
    },
    addTodo: function addTodo(e) {
      var text = e.target.value;

      if (text.trim()) {
        this.todos.push({
          text: text,
          done: false
        });
        this.setLocalStorage();
      }

      e.target.value = '';
    },
    toggleTodo: function toggleTodo(val) {
      val.done = !val.done;
      this.setLocalStorage();
    },
    deleteTodo: function deleteTodo(todo) {
      this.todos.splice(this.todos.indexOf(todo), 1);
      this.setLocalStorage();
    },
    editTodo: function editTodo(_ref) {
      var todo = _ref.todo,
          value = _ref.value;
      todo.text = value;
      this.setLocalStorage();
    },
    clearCompleted: function clearCompleted() {
      this.todos = this.todos.filter(function (todo) {
        return !todo.done;
      });
      this.setLocalStorage();
    },
    toggleAll: function toggleAll(_ref2) {
      var _this = this;

      var done = _ref2.done;
      this.todos.forEach(function (todo) {
        todo.done = done;

        _this.setLocalStorage();
      });
    }
  }
};